// import axios from 'axios'

function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export default {
  methods: {
    async api(method, params = {}, requestTry = 2) {
      /** Автоматом передаются язык и тестовый режим
       * TODO: возможно в конце сделать чтоб тестовый режим выбирался по домену:
       * для боевого - всегда testMode=false
       * для тестового - всегда testMode=true
       * для локального - брался из app.$CONST.testMode
       */
      params.testMode = this.$CONST.testMode
      const id = Date.now()
      const key = this.$CONST.key
      const lang = this.$i18n.locale
      const url = `${this.$CONST.apiUrl}/${method}`

      let data
      try {
        const result = await this.$axios.post(
          url,
          { id, key, lang, params },
          {
            withCredentials: true,
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
          },
        )
        data = result.data
      } catch (error) {
        console.warn(
          `error in method ${method}(try ${requestTry}): `,
          'get-personalizations-groups: first run error',
          error,
        )
        if (requestTry > 0) {
          requestTry--
          sleep(500)
          data = await this.api(method, params, requestTry)
        } else {
          data = { id, error }
        }
      }
      return data
    },
  },
}
